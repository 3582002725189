.list-enter {
    opacity: 0;
}

.list-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.list-exit {
    opacity: 1;
    transform:translateX(0);
}

.list-exit-active {
    opacity: 0;
    transform:translateX(-100%);
    transition: opacity 250ms ease-in, transform 750ms;
}


.footerList-enter {
    bottom: 0;
    opacity: 0;
}

.footerList-enter-active {
    bottom: 28px;
    opacity: 1;
    transition: bottom 500ms, opacity 250ms
}

.footerList-exit {
    opacity: 1;
    bottom: 28px;
}

.footerList-exit-active {
    bottom: 0;
    opacity: 0;
    transition:  bottom 500ms, opacity 500ms
} 


.search-enter {
    bottom: 0;
}

.search-enter-active {
    bottom: 80px;
    transition: bottom 500ms
}

.search-exit {
    bottom: 80px;
}

.search-exit-active {
    bottom: 0;
    transition:  bottom 500ms
}